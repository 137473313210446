<div class="checkout-container">
  <div class="progress-container">
    <nz-progress
      [nzPercent]="getProgressPrecent()"
      [nzShowInfo]="false"
      [nzStrokeWidth]="3"
    ></nz-progress>
  </div>

  <!-- FIRST STEP -->
  <div *ngIf="currentStep === 1">
    <div class="billing-container">
      <h2>Billing Address</h2>
      <form>
        <div class="input-container">
          <label for="Fullname">Full name</label>
          <input
            nz-input
            type="text"
            name="Fullname"
            placeholder="Enter Full name"
            [(ngModel)]="Fullname"
          />
        </div>
        <div class="input-container">
          <label for="Email">Email address</label>
          <input
            nz-input
            type="email"
            name="Email"
            placeholder="Enter Email address"
            [(ngModel)]="Email"
          />
        </div>
        <div class="input-container">
          <label for="Addressline1">Address line 1</label>
          <input
            nz-input
            type="text"
            name="Addressline1"
            placeholder="Enter Address line 1"
            [(ngModel)]="Addressline1"
          />
          <nz-alert  *ngIf="!isValidAddressLine1()" nzType="error" nzMessage="Please enter a valid Address line 1" nzShowIcon></nz-alert>
        </div>
        <div class="input-container">
          <label for="Addressline2">Address line 2</label>
          <input
            nz-input
            type="text"
            name="Addressline2"
            placeholder="Enter Address line 2"
            [(ngModel)]="Addressline2"
          />
        </div>
        <div class="input-container">
          <label for="City">City</label>
          <input
            nz-input
            type="text"
            name="City"
            placeholder="Enter City"
            [(ngModel)]="City"
          />
        </div>
        <div *ngIf="false" class="input-container">
          <label for="Country">Country</label>
          <input
            nz-input
            type="text"
            name="Country"
            placeholder="Enter Country"
            [(ngModel)]="Country"
          />
        </div>
        <div *ngIf="false" class="input-container">
          <label for="ZIP">ZIP Code</label>
          <input
            nz-input
            type="text"
            name="ZIP"
            placeholder="Enter ZIP code"
            [(ngModel)]="ZIP"
          />
        </div>
        <div class="input-container">
          <label for="Telephone">Telephone</label>
          <input
            nz-input
            type="number"
            name="Telephone"
            placeholder="Enter Telephone"
            [(ngModel)]="Telephone"
          />
          <nz-alert  *ngIf="!isValidTelephone()" nzType="error" nzMessage="Please enter a valid Telephone" nzShowIcon></nz-alert>
        </div>
        <button
        nz-button
        (click)="submitBilling()"
        [disabled]="canBillingSubmit()"
      >
        Continue
      </button>
      </form>
      
    </div>
  </div>
  <!-- SECOND STEP
  <div *ngIf="currentStep === 2">
    <div class="payment-container">
      <h2>Payment Details</h2>
      <form>
        <div class="input-container">
          <label for="">Card number</label>
          <input
            nz-input
            type="text"
            name="cardNumber"
            [(value)]="cardNumber"
            [(ngModel)]="cardNumber"
            placeholder="1234 1234 1234 1234"
          />
        </div>
        <div class="input-container">
          <label for="">Name on card</label>
          <input
            nz-input
            type="text"
            name="cardName"
            [(value)]="cardName"
            [(ngModel)]="cardName"
            placeholder="John Doe"
          />
        </div>
        <div class="‼️">
          <div class="input-container">
            <label for="">Expiry date</label>
            <input
              nz-input
              type="text"
              name="cardExpiry"
              [(value)]="cardExpiry"
              [(ngModel)]="cardExpiry"
              placeholder="06/30"
            />
          </div>
          <div class="input-container">
            <label for="">Security code</label>
            <input
              nz-input
              type="text"
              name="cardCode"
              [(value)]="cardCode"
              [(ngModel)]="cardCode"
              placeholder="123"
            />
          </div>
        </div>
      </form>
      <button
        nz-button
        [disabled]="!canPaymentSubmit()"
        (click)="submitPayment()"
      >
        Continue
      </button>
    </div>
  </div> -->
  <!-- THIRD STEP -->
  <div *ngIf="currentStep === 2"> 
    <div class="summary-container">
      <div class="summary-container__order">
        <h2>Order Summary</h2>
        <div class="details">
          <div><span>Item(s)</span> {{ cartData.products.length }}</div>
          <div *ngIf="Paymentmode>0" class="price">
            <span>
              <h3>Payment Mode</h3> 
            </span>
              <nz-select [(ngModel)]="Paymentmode" nzDisabled>
                <nz-option  *ngFor="let p of paymentmodes" [nzValue]="p.value" [nzLabel]="p.viewValue" >   
                </nz-option>    
              </nz-select>
              <ng-template #defaultTemplate let-selected>
                {{ selected.nzLabel }}
              </ng-template>
          </div>
          <!-- <div class="col-sm-8 float-left">
            <mat-form-field appearance="fill">
              <mat-label>Payment method</mat-label>
              <mat-select>
                <mat-option *ngFor="let payment of paymentmodes" [value]="payment.viewValue">
                  {{payment.viewValue}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->
          <div>
            <span>Amount</span> {{ cartData.total | currency: "Rs." }}
          </div>
          <div>
            <span>Shipping charges</span> {{ "400.00" | currency: "Rs." }}
          </div>
          <div>
            <span>Total amount</span> {{ cartData.total+400 | currency: "Rs." }}
          </div>
        </div>
        <button
          nz-button
          nzType="primary"
          (click)="submitCheckout()"
          [nzLoading]="loading"
        >
          Place order
        </button>
      </div>
      <div class="summary-container__items">
        <h2>Order Items</h2>
        <div class="item-list">
          <div class="item-container" *ngFor="let item of cartData.products">
            <div class="item-image">
              <img [src]="item.image" alt="" />
            </div>
            <div class="item-title">
              {{ item.title }}
            </div>
            <div class="item-total">
              {{ item.price | currency: "Rs." }} x {{ item.quantity }} =
              {{ item.quantity * item.price | currency: "Rs." }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- LAST STEP - THANK YOU -->
  <div *ngIf="currentStep === 3">
    <div class="confirmation-container">
      <header>
        <div class="title">
          <h1>Thank you!</h1>
          <!-- <p>Please check your email for the order confirmation</p> -->
          <p>Order placed successfully.Please check your order history for track the order status</p>
        </div>
        <div class="order-id">
          Order ID: <span>{{ orderId }}</span>
        </div>
        <br/>
        <div *ngIf="SavedPaymentmode === '2'"  class="title">
          <!-- <p>Please check your email for the order confirmation</p> -->
          <p>Please make your bank transfer to the following account, Kindly mention <b> {{ orderId }} </b> as reference</p>
          <p><b>Account Name   : V. Sinduja</b></p>
          <p><b>Bank,Branch    : Commercial bank,Dehiwala</b></p>
          <p><b>Account Number : 8004119155</b></p>
          <p><b>Amount         : {{ total+400 | currency: "Rs." }}</b></p>         
        </div>
      </header>
      <button nz-button class="cta-button" routerLink="/">
        Continue shopping
      </button>
      <div class="products-container">
        <h2>Order Summary</h2>
        <div class="products-container__list">
          <div class="list-item" *ngFor="let item of products">
            <div class="item-image">
              <img [src]="item.image" alt="" />
            </div>
            <div class="item-title">
              {{ item.title }}
            </div>
            <div class="item-total">
              {{ item.price | currency: "Rs." }} x {{ item.quantity }} =
              {{ item.quantity * item.price | currency: "Rs." }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
