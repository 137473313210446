import { Component, OnInit } from '@angular/core';
import { ProductService } from '../services/product.service';
import { Product } from '../shared/models/product.model';

@Component({
  selector: 'app-item-search',
  templateUrl: './item-search.component.html',
  styleUrls: ['./item-search.component.scss']
})
export class ItemSearchComponent implements OnInit {

  searchtitle = 'angular-text-search-highlight';
  searchText = '';
  error = '';
  loading = false;
  products: Product[] = [];
  constructor(private productService: ProductService) {

   }

  ngOnInit(): void {

    this.loading = true;
    setTimeout(() => {
      this.productService.getAllProductsSummary(100000, 1).subscribe(
        (res: any) => {
          //console.log(res);
          this.products = res;
          this.loading = false;
          console.log(this.products);
        },
        (err) => {
          console.log(err);
          this.loading = false;
        }
      );
    }, 500);
  }

  }
