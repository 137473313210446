import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { CartService } from '../services/cart.service';

interface paymentmode {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})




export class CheckoutComponent implements OnInit {
  currentUser: any;
  currentStep = 1;
  cardNumber: string;
  cardName: string;
  cardExpiry: string;
  cardCode: string;
  cartData: any;
  products: any;
  loading = false;
  successMessage = '';
  orderId;
  Fullname: string;
  Email: string;
  Addressline1: string;
  Addressline2: string;
  City: string;
  Country: string='NA';
  ZIP: string='0000';
  Telephone: string;
  total=0;

  paymentmodes: paymentmode[] = [
    {value: '0', viewValue: 'Select Payment'},
    {value: '1', viewValue: 'Cash on delivery'},
    {value: '2', viewValue: 'Bank transfer'},
  ];

  Paymentmode=0;
  SavedPaymentmode = 0 ;


  constructor(private _auth: AuthService, private _cart: CartService) {
    this._auth.user.subscribe((user) => {      
      if (user) {
        this.currentUser = user;
        console.log(user);
        if(user.email!="guest@sinduscollection.lk")
        {
          this.billingAddress[0].value = user.full_name;
          this.billingAddress[1].value = user.email;
          this.Fullname = user.full_name;
          this.Email = user.email;
        }
      }
    });

    this._cart.cartDataObs$.subscribe((cartData) => {
      this.cartData = cartData;
      console.log(this.cartData.paymentmode);
      this.Paymentmode=this.cartData.paymentmode;
    });
  }

  ngOnInit(): void { 
   }

  submitCheckout() {

    this.billingAddress[0].value = this.Fullname;
    this.billingAddress[1].value = this.Email;
    this.billingAddress[2].value = this.Addressline1;
    this.billingAddress[3].value = this.Addressline2;
    this.billingAddress[4].value = this.City;
    this.billingAddress[5].value = this.Country;
    this.billingAddress[6].value = this.ZIP;
    this.billingAddress[7].value = this.Telephone;
    
    this.loading = true;
    this.total = this._cart.getCartTotal();

    setTimeout(() => {

      this._cart
        .submitCheckout(this.currentUser.id, this.cartData,this.billingAddress)
        .subscribe(
          (res: any) => {
            console.log(res);
            this.loading = false;
            this.orderId = res.orderId;
            this.products = res.products;
            this.SavedPaymentmode=res.paymentmode;
            this.currentStep = 3;
            this._cart.clearCart();
          },
          (err) => {
            console.log(err);
            this.loading = false;
          }
        );
    }, 750);
  }

  getProgressPrecent() {
    return (this.currentStep / 3) * 100;
    //return (this.currentStep / 4) * 100;
    
  }

  submitBilling(): void {
    this.nextStep();
  }

  canBillingSubmit(): boolean {
        return this.isValidTelephone() && this.isValidAddressLine1() && this.Fullname && this.Email && this.Addressline1 && this.Addressline2 && this.City && this.Country && this.ZIP && this.Telephone? false : true;
  }

  isValidTelephone(): boolean {
        return  (this.Telephone == undefined ? true : (this.Telephone && this.Telephone.toString().length>=9));
}

isValidAddressLine1(): boolean {
      return  (this.Addressline1 == undefined ? true : (this.Addressline1 && this.Addressline1.length>=5));
}

  submitPayment(): void {
    this.nextStep();
  }

  canPaymentSubmit(): boolean {
    return this.cardNumber && this.cardName && this.cardExpiry && this.cardCode
      ? true
      : false;
  }

  nextStep(): void {
    this.currentStep += 1;
    localStorage.setItem('checkoutStep', this.currentStep.toString());
  }

  prevStep(): void {
    if (this.currentStep > 1) {
      this.currentStep -= 1;
      localStorage.setItem('checkoutStep', this.currentStep.toString());
    }
  }

  billingAddress = [
    {
      name: 'Fullname',
      placeholder: 'Enter your full name',
      type: 'text',
      value: '',
    },
    {
      name: 'Email',
      placeholder: 'Enter your email address',
      type: 'email',
      value: '',
    },
    {
      name: 'Addressline1',
      placeholder: 'Enter your address line 1',
      type: 'text',
      value: '',
    },
    {
      name: 'Addressline2',
      placeholder: 'Enter your address line 2',
      type: 'text',
      value: '',
    },
    {
      name: 'City',
      placeholder: 'Enter your city',
      type: 'text',
      value: '',
    },
    {
      name: 'Country',
      placeholder: 'Enter your country',
      type: 'text',
      value: '',
    },
    {
      name: 'ZIP',
      placeholder: 'Enter your zip code',
      type: 'text',
      value: '',
    },
    {
      name: 'Telephone',
      placeholder: 'Enter your telephone number',
      type: 'text',
      value: '',
    },
  ];
}
