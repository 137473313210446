<div class="product-container" *ngIf="!loading">
  <div>
    <div class="product-container__image">
      <!-- Single Image -->
      <swiper
        [zoom]="true"
        [slidesPerView]="1"
        [spaceBetween]="50"
        [pagination]="true"
        *ngIf="showcaseImages.length === 0"
      >
        <ng-template swiperSlide [zoom]="true">
          <div class="product-card__image">
            <img src="{{ product.image }}" alt="`Product image`" />
          </div>
        </ng-template>
      </swiper>
      <!-- Multiple images -->
      <swiper 
        [navigation]="true" 
        [zoom]="true"
        [slidesPerView]="1"
        [spaceBetween]="50"
        [pagination]="pagination"
        class="mySwiper"
        *ngIf="showcaseImages.length > 0"
      >
        <ng-template
          swiperSlide
          [zoom]="true"
          *ngFor="let image of showcaseImages; index as i"
        >
          <img src="{{ image }}" alt="`Product image`" />
        </ng-template>
      </swiper>
    </div>
    <div class="product-container__details">
      <h2 class="title">{{ product.title }}</h2>
      <div class="reviews">
        <div class="rating">
          <i nz-icon nzType="star" nzTheme="fill"></i>
          <i nz-icon nzType="star" nzTheme="fill"></i>
          <i nz-icon nzType="star" nzTheme="fill"></i>
          <i nz-icon nzType="star" nzTheme="fill"></i>
          <i nz-icon nzType="star"></i>
        </div>
        <span class="review-link"
          >10 Review(s) |
          <span style="cursor: pointer">Add your review</span></span
        >
      </div>
      <div class="price-container">
        <h2 class="price"  *ngIf="isSale"><s>{{ product.price | currency: "Rs." }}</s></h2>
        <h2 class="price"  *ngIf="!isSale">{{ product.price | currency: "Rs." }}</h2>
        <span
          class="availability"
          [ngStyle]="{ color: quantity > 0 ? 'inherit' : 'red' }"
          >{{ quantity <= 0 ? "Out of stock" : "In stock" }}</span
        >
      </div>
      <div class="price-container" *ngIf="isSale">
        <h2 class="price">{{ product.salePrice | currency: "Rs." }}</h2>
      </div>
      <div class="description">{{ product.description }}</div>
      <div class="cta">
        <div class="add-to-cart">
          <span>QTY:</span>
          <nz-input-number
            [(ngModel)]="quantity"
            [nzMin]="product.quantity > 0 ? 1 : 0"
            [nzMax]="product.quantity > 0 ? product.quantity : 0"
            [nzStep]="1"
          ></nz-input-number>
          <button
            nz-button
            nzType="primary"
            (click)="addToCart()"
            [disabled]="quantity <= 0"
          >
            Add to cart
          </button>
        </div>
        <div class="category">
          <span>Category: </span> {{ product.category }}
        </div>
        <div class="social-links">
          <span>Share: </span>
          <i nz-icon nzType="facebook" nzTheme="outline"></i>
          <i nz-icon nzType="twitter" nzTheme="outline"></i>
          <i nz-icon nzType="google-plus" nzTheme="outline"></i>
          <i nz-icon nzType="mail" nzTheme="outline"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="additional"></div>
</div>