import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from 'src/app/services/product.service';
import { ApiService } from '../services/api.service';
import { FormBuilder, FormGroup } from "@angular/forms";
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {
  id : number = 0;
  title = '';
  images :any[] =[];
  categories: any[] = [];
  description = '';
  price :number = 0;
  quantity  :number = 0;
  short_desc = '';
  cat_id : string = '1';
  errorMessage = '';
  loading = false;
  salePrice  :number = 0;

  imageURL1: string;
  imageURL2: string;
  imageURL3: string;
  imageURL4: string;

  uploadForm: FormGroup;

  constructor(
    private _api: ApiService,
    private _productService: ProductService,
    private route: ActivatedRoute,
    private _product: ProductService,
    private _router: Router,public fb: FormBuilder,private _notification: NzNotificationService,
  ) {
    // Reactive Form
    this.uploadForm = this.fb.group({
      avatar: [null],
      name: ['']
    });
  }

  ngOnInit(): void {

    setTimeout(() => {
      this._product.getAllCategories().subscribe(
        (res: any) => {
          //console.log(res);
          this.categories = res;
          this.loading = false;
        },
        (err) => {
          console.log(err);
          this.loading = false;
        }
      );
    }, 500);
    
    if(this.route.snapshot.params.itemId)
    {
        this.id=this.route.snapshot.params.itemId;
        console.log(this.id);

        this.route.queryParams.subscribe(params => {
          console.log(params['itemId']);
      });

      this.LoadData();      

    }

  }

  onSubmit(): void {

    this.cat_id = (<HTMLInputElement>document.getElementById("selected_cat_id")).value

    this.errorMessage = '';
    if (this.canSubmit) {
        this.loading = true;
            this._productService.saveProduct(
              {id:this.id,
                title:  this.title,
                image1: this.imageURL1,
                image2:  this.imageURL2,
                image3:  this.imageURL3,
                image4:  this.imageURL4,
                description:     this.description,
                price:   this.price,
                quantity:  this.quantity,
                cat_id:  this.cat_id,
                short_desc:this.short_desc,
                salePrice:this.salePrice}
            ).subscribe(
              (res: any) => {
                console.log(res);
                this.loading = false;
                this._notification.create(
                  'success',
                  'Create/Update Item',
                  `${this.title} was successfully Created`
                );
                if(this.id>0)
                {
                    this._router.navigate(['/item-search']);    
                }
                else
                {
                  this.ClearForm();
                }
              },
              (err) => {
                console.log(err);
                this.loading = false;
                this._notification.create(
                  'error',
                  'Create/Update Item',
                  `${err.error.message}`
                );
                this.loading = false;
              }
            );

      }
     else {
      this.errorMessage = 'Make sure to fill everything ;)';
    }
  }

  canSubmit(): boolean {
    return this.title && this.description && this.price && this.price >=0 &&  this.quantity >=0  
      ? true
      : false;
  }

  // Image Preview
  showPreview(event, imageId:number) {
    const file = (event.target as HTMLInputElement).files[0];
    this.uploadForm.patchValue({
      avatar: file
    });
    this.uploadForm.get('avatar').updateValueAndValidity()
    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      switch(imageId)
      {
        case 1:
          this.imageURL1 = reader.result as string;
          console.log(this.imageURL1);
          break;
        case 2:
          this.imageURL2 = reader.result as string;
          break;
        case 3:
          this.imageURL3 = reader.result as string;
          break;
        case 4:
          this.imageURL4 = reader.result as string;
          break;
        default:
          break;
      }
    }
    reader.readAsDataURL(file)

  }

  // Submit Form
  submit() {
    console.log(this.uploadForm.value)
  }


  ClearForm()
  {
    this.id = 0;
    this.title = '';
    this.images =[];
    this.description = '';
    this.price = 0;
    this.salePrice = 0;
    this.quantity = 0;
    this.short_desc = '';
    this.cat_id = '1';
    this.errorMessage = '';
    this.loading = false;

    this.imageURL1='';
    this.imageURL2='';
    this.imageURL3='';
    this.imageURL4='';
  }

  LoadData()
  {

    this._product.getSingleProduct(this.id).subscribe((product) => {
      console.log(product);
      
      if (product.quantity === 0) this.quantity = 0;
      else this.quantity = 1;

      this.title = product.title;
    this.description = product.description;
    this.price = product.price;
    this.short_desc = product.short_desc;
    this.cat_id = product.cat_id;
    this.quantity = product.quantity;
    this.salePrice = product.salePrice;

      if(product.image1!='')
        this.imageURL1=(product.image1);
      if(product.image2!='')          
        this.imageURL2=(product.image2);
      if(product.image3!='')          
        this.imageURL3=(product.image3);
      if(product.image4!='')
        this.imageURL4=(product.image4);

      this.loading = false;
    });
    
  }
}
