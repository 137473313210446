<div class="product-card">
  <div class="product-card__image" routerLink="/product/{{ id }}">
    <img src="{{ image }}" alt="" />
  </div>
  <div class="product-card__info">
    <header>
      <div class="title">
        <div class="product-name">{{ title }}</div>
        <div class="product-short-desc">{{ short_desc }}</div>
      </div>
      <div class="title">
        <div class="product-price" *ngIf="isSale"><s>{{ price | currency: "Rs." }}</s></div>
        <div class="product-price" *ngIf="!isSale">{{ price | currency: "Rs." }}</div>
        <div class="product-price"  *ngIf="isSale">{{ salePrice | currency: "Rs." }}</div>
      </div>
     
    </header>
  </div>
  <div class="product-card__cta">
    <button
      nz-button
      [disabled]="quantity === 0"
      (click)="
        onAdd({
          id: id,
          price: unitPrice,
          image: image,
          title: title,
          maxQuantity: quantity
        })
      "
    >
      {{ quantity <= 0 ? "Out of stock" : "Add to cart" }}
    </button>
  </div>
</div>
