<div class="profile-container">
  <div class="form-container">
    <h2>Profile</h2>
    <nz-alert
      [nzType]="alertType"
      nzShowIcon
      [nzMessage]="alertMessage"
      [ngStyle]="{ marginBottom: '12px' }"
      *ngIf="alertVisible"
    ></nz-alert>
    <form (ngSubmit)="onSubmit()">
      <div class="input-container" *ngFor="let field of user">
        <label for="field.key">{{ field.label }}</label>
        <input
          nz-input
          [name]="field.key"
          [type]="field.type"
          [(value)]="field.value"
          [(ngModel)]="field.value"
        />
      </div>
      <div class="cta-container">
        <button
          nz-button
          type="submit"
          [nzLoading]="loading"
          [disabled]="canUpdate()"
        >
          Update
        </button>
      </div>
    </form>
  </div>
</div>
