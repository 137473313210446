import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { ProductService } from '../services/product.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {
  user: any;
  orders: any[] = [];
  orderHeader : any;
  orderStatus : any[]=[];
  error = '';
  order_id:number;
  orderstatus_id:any=0;
  orderstatustext:any='Pending';
  loading = false;
  notes;

  constructor(
    private _api: ApiService,
    private _auth: AuthService,
    private _product: ProductService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.user = this._auth.getUser();
  }

  ngOnInit(): void {
    this.loading = true;

    if(this.route.snapshot.params.orderId)
    {

    this.order_id=this.route.snapshot.params.orderId;
    console.log(this.order_id);

    this._api.getTypeRequest(`orders/getorderheaderbyid/${this.order_id}`).subscribe(
      (res: any) => {
        this.orderHeader=res.data[0];
        this.orderstatus_id=this.orderHeader.orderstatus_id;  
        this.notes=this.orderHeader.notes;  
        //this.orderstatus_id='Pending';  

        //console.log(this.orderstatus_id);

      },
      (err) => {
        this.error = err.error.message;
        this.loading = false;

      }
    );

    this._api.getTypeRequest(`orders/getorderstatusbyorderid/${this.order_id}`).subscribe(
      (res: any) => {
        this.orderStatus=res.data;
        //console.log(this.orderStatus);
        
      },
      (err) => {
        this.error = err.error.message;
        this.loading = false;

      }
    );

    this._api.getTypeRequest(`orders/singleadmin/${this.order_id}`).subscribe(
      (res: any) => {
        this.orders=res.data;
        console.log(this.orders);
        // let uniqueProductsArray = Array.from(
        //   new Set(res.data.map((p) => p.product_id))
        // );
        this.loading = false;
      },
      (err) => {
        this.error = err.error.message;
        this.loading = false;

      }
    );
    

    }
  }

  submit() {
    //this.orderstatus_id = (<HTMLInputElement>document.getElementById("selectorderstaus")).value    
    setTimeout(() => {
      this._api.postTypeRequest('orders/updateorderstatusbyorderid/'+ this.order_id + '&orderStatusId='+this.orderstatus_id+ '&notes='+this.notes, {
        orderStatusId: this.orderstatus_id,
        orderId: this.order_id,
      }).subscribe(
          (res: any) => {
            //console.log(res);
            this.router.navigate([`../getOrderSummary`]);
          },
          (err) => {
            console.log(err);
          }
        );
    }, 750);
  }
}
