<div class="orders-container">
<div class="row">
  <div class="col-md-12 mx-auto">
    <div class="card mx-4">
      <div class="card-header">
        <button   class="btn btn-md pull-right btn-primary" type="button"
        routerLink="/item"><i class="fa fa-fw fa-plus"></i>Create Item</button>
          <h3 class="mb-0">Item Management</h3>
      </div>
      <div class="card-body p-4">
        <table datatable [dtOptions]="dtOptions"  class="table table-striped table-bordered" style="width:100%" id="gridView">
          <thead class="thead-light">
            <tr>
              <th ></th>
              <th style="min-width : 20%">Category</th>
              <th style="min-width: 25%">Item</th>
              <th>Price</th>                     
              <th>Quantity</th> 

             <th class="text-center"></th>
            </tr>
          </thead>
          <!-- <tbody *ngIf="products.length > 0"></tbody> -->
          <tbody ></tbody>

          <tbody *ngIf="products.length ==0">
            <tr>
              <div>{{v?.length }}</div>
              <td colspan="3" class="no-data-available">No data!</td> 
            </tr>
          <tbody>
        </table>
      </div>
    </div>
  </div>
</div>
</div>

