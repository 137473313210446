import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CartService } from '../services/cart.service';


interface paymentmode {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  cartData: any;
  Paymentmode=0;

  paymentmodes: paymentmode[] = [
    {value: '0', viewValue: 'Select Payment'},
    {value: '1', viewValue: 'Cash on delivery'},
    {value: '2', viewValue: 'Bank transfer'},
  ];


  
  constructor(private _cart: CartService,private _router: Router,private _notification: NzNotificationService) {
    this._cart.cartDataObs$.subscribe((cartData) => {
      this.cartData = cartData;
      this.Paymentmode=this.cartData.paymentmode;
      console.log(cartData);
    });
  }

  ngOnInit(): void {}

  updateCart(id: number, quantity: number): void {
    console.log({ id, quantity });
    this._cart.updateCart(id, quantity);
  }

  removeCartItem(id: number): void {
    this._cart.removeProduct(id);
  }

  checkout():void{
    if(this._cart.cartData.paymentmode>0)
    {
        this._router.navigate(['/checkout']); 
    }
    else
    {
      this._notification.create(
        'error',
        'Payment Mode',
        'Please select a Payment Mode'
      );
    }
    // routerLink="/checkout"
  }

  onChange():void{
    if(this.Paymentmode>=0)
    {
      this._cart.updateCartPayment(this.Paymentmode);
    }
  }


}
