<div class="cart-container">
  <div class="cart-container__empty" *ngIf="cartData.products.length === 0">
    Your cart is empty.
  </div>
  <div class="cart-container__list" *ngIf="cartData.products.length > 0">
    <header class="list-header">
      <h2>Cart</h2>
    </header>
    <div
      class="list-item"
      *ngFor="let product of cartData.products; index as i"
      [ngStyle]="{ border: i === cartData.products.length - 1 && 'none' }"
    >
      <div class="list-item__remove" (click)="removeCartItem(product.id)">
        X
      </div>
      <div class="list-item__image">
        <img [src]="product.image" alt="" />
      </div>
      <div class="list-item__details">
        <div class="title">{{ product.title }}</div>
        <div class="price">{{ product.price | currency: "Rs." }}</div>
        <div class="cta">
          <nz-input-number
            [(ngModel)]="product.quantity"
            [nzMin]="product.maxQuantity > 0 ? 1 : 0"
            [nzMax]="product.maxQuantity > 0 ? product.maxQuantity : 0"
            [nzStep]="1"
            (ngModelChange)="updateCart(product.id, product.quantity)"
          ></nz-input-number>
        </div>
      </div>
    </div>
  </div>
  <div class="cart-container__total">
    <div class="price">
      <span>
        <h3>Amount</h3>
        <h2>{{ cartData.total | currency: "Rs." }}</h2></span>
      <span>
          <h3>Shipping charges</h3>
          <h2>{{ "400.00" | currency: "Rs." }}</h2></span>
      <span>
        <h3>Total amount</h3>
        <h2>{{ cartData.total+400 | currency: "Rs." }}</h2></span>
      <span>
        <h3>No of items</h3>
        <h2>{{ cartData.products.length }}</h2>
      </span>

      <div class="price">
        <span>
          <h3>Payment Mode</h3> 
          <nz-select [(ngModel)]="Paymentmode" (ngModelChange)="onChange()">
            <nz-option  *ngFor="let p of paymentmodes" [nzValue]="p.value" [nzLabel]="p.viewValue" >   
            </nz-option>    
          </nz-select>
          <ng-template #defaultTemplate let-selected>
            {{ selected.nzLabel }}
          </ng-template>
        </span>
      </div>
    </div>
    <div class="cta">
      <button
        nz-button
        nzType="primary"
        (click)="checkout()"
        [disabled]="cartData.products.length === 0"
      >
        Checkout
      </button>
      <button nz-button routerLink="/">Back to shopping</button>
    </div>
  </div>
</div>
