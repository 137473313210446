 
<div id="sides">
    <div id="left">      
<div class="register-container">
    <div class="form-container">
      <h2>Category</h2>
      <nz-alert
        *ngIf="errorMessage"
        nzShowIcon
        nzType="error"
        [nzMessage]="errorMessage"
        [ngStyle]="{ 'margin-bottom': '16px' }"
      ></nz-alert>
      <form #registerForm="ngForm" (ngSubmit)="onSubmit()">
        <div class="input-container">
          <label for="title">Category Name</label>
          <input
            nz-input
            type="text"
            name="title"
            [(ngModel)]="title"
            placeholder="Enter Category Name"
          />
        </div>
          <div class="‼️">
            <div class="input-container">
              <label for="orderby">Order by</label>
              <input  
                type="number"
                min="0" 
                nz-input
                name="orderby"
                [(value)]="orderby"
                [(ngModel)]="orderby"
              />
            </div>
          </div>  
          <div >
            <label for="hide">Hide</label>
            <input               
              type="checkbox"
              name="hide"
              [(value)]="hide"
              [(ngModel)]="hide"
            />
          </div>        
        <div class="cta-container">
          <!-- <input type="submit" value="Sign up" /> -->
          <button
            nz-button
            type="submit"
            [disabled]="!canSubmit()"
            [nzLoading]="loading"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<div id="right">
    <div class="register-container">
        <div class="form-container">
          <h2>Images</h2>
          <form [formGroup]="uploadForm" (ngSubmit)="submit()">
            
            <!-- Select File -->
            <input type="file" accept="image/*" (change)="showPreview($event,1)" />
            <!-- Image Preview -->
            <div class="product-card__image" *ngIf="imageURL && imageURL !== ''">
              <img [src]="imageURL" [alt]="uploadForm.value.name" style='height: 100%; width: 100%; object-fit: contain'>
            </div>
          </form>
          
        </div>
      </div>
</div>
</div>

  