import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from '../services/api.service';
import { ProductService } from '../services/product.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
//title,image,hide,orderby

  id : number = 0;
  title = '';
  orderby :number = 0;
  hide  :number = 0;
  errorMessage = '';
  loading = false;

  imageURL: string;

  uploadForm: FormGroup;
  constructor(
    private _api: ApiService,
    private _productService: ProductService,
    private route: ActivatedRoute,
    private _product: ProductService,
    private _router: Router,public fb: FormBuilder,private _notification: NzNotificationService,
  ) { 
    // Reactive Form
    this.uploadForm = this.fb.group({
      avatar: [null],
      name: ['']
    });
  }

  ngOnInit(): void {
    
    if(this.route.snapshot.params.categoryId)
    {
        this.id=this.route.snapshot.params.categoryId;

        this.LoadData();      

    }

  }

  onSubmit(): void {

    this.errorMessage = '';
    if (this.canSubmit) {
        this.loading = true;
            this._productService.saveCategory(
              { 
                id:this.id,
                title:  this.title,
                image: this.imageURL,
                orderby: this.orderby,
                hide: this.hide
                }
            ).subscribe(
              (res: any) => {
                console.log(res);
                this.loading = false;
                this._notification.create(
                  'success',
                  'Create/Update Category',
                  `${this.title} was Category Created`
                );
                if(this.id>0)
                {
                    this._router.navigate(['/category-search']);    
                }
                else
                {
                  this.ClearForm();
                }
              },
              (err) => {
                console.log(err);
                this.loading = false;
                this._notification.create(
                  'error',
                  'Create/Update Category',
                  `${err.error.message}`
                );
                this.loading = false;
              }
            );

      }
     else {
      this.errorMessage = 'Make sure to fill everything ;)';
    }
  }

  canSubmit(): boolean {
    return this.title && this.orderby 
      ? true
      : false;
  }

  // Image Preview
  showPreview(event, imageId:number) {
    const file = (event.target as HTMLInputElement).files[0];
    this.uploadForm.patchValue({
      avatar: file
    });
    this.uploadForm.get('avatar').updateValueAndValidity()
    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      switch(imageId)
      {
        case 1:
          this.imageURL = reader.result as string;
          break;
        default:
          break;
      }
    }
    reader.readAsDataURL(file)

  }

  // Submit Form
  submit() {
    console.log(this.uploadForm.value)
  }


  ClearForm()
  {
    this.id = 0;
    this.title = '';
    this.orderby = 0;
    this.hide = 0;
    this.errorMessage = '';
    this.loading = false;
    this.imageURL='';
  }

  LoadData()
  {

    this._product.getSingleCategory(this.id).subscribe((category) => {
      console.log(category);
      
    this.title = category.title;
    this.orderby = category.orderby;
    this.hide = category.hide;

    if(category.image!='')
        this.imageURL=(category.image);

      this.loading = false;
    });
    
  }

}
