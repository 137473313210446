<div class="cart-container">
  <div *ngIf="loading" class="load__loading" >
    <ng-template #indicatorTemplate>
      <i
        nz-icon
        nzType="loading"
        [ngStyle]="{ 'font-size': '2rem', margin: '0 auto' }"
      ></i>
    </ng-template>
    <nz-spin nzSimple [nzIndicator]="indicatorTemplate"> </nz-spin>
  </div>
<div class="cart-container__list"  *ngIf="!loading">
    <h2>Order Details</h2>
    {{ error }}
    <nz-table #basicTable [nzData]="orders" *ngIf="orders.length > 0">
      <thead>
        <tr>
          <th></th>
          <th>Name</th>
          <th>Quantity</th> 
          <th>Price</th>        
          <th>Amount</th>       
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of basicTable.data">
            <td>
                <div class="image">
                    <img src="{{ item.image1 }}"  alt="" />
                  </div>
            </td>
          <td>{{ item.title }}</td>
          <td>{{ item.quantity }}</td> 
          <td>{{ item.price }}</td>       
          <td>{{ item.amount }}</td>       
        </tr>
      </tbody>
    </nz-table>
  </div>
  <div class="cart-container__total"  *ngIf="!loading">
    <div>
      <span *ngIf="orderHeader"> 
        <h2>Order Id - {{orderHeader.order_id}}</h2>
        </span>
    </div>
    
    <div  style="display: inline-block; vertical-align: middle; line-height: 1;">
      <span  *ngIf="orderHeader">Date - {{orderHeader.order_date | date :'dd/MM/yy'}}</span>
    </div>
    <div>
      <span  *ngIf="orderHeader">Customer - {{orderHeader.fname}}</span>
    </div>
    <div>
      <span  *ngIf="orderHeader">Phone - {{orderHeader.phone}}</span>
    </div>
    <div  *ngIf="orderHeader">Address L1- {{orderHeader.line1}}</div>
    <div  *ngIf="orderHeader">Address L2- {{orderHeader.line2}}</div>
    <div  *ngIf="orderHeader">City - {{orderHeader.city}}</div>
    <div class="price">
      <span>
        <h3>Shipping charges</h3>
        <h2>400.00</h2>
      </span>
      <span>
        <h3>Total amount</h3>
        <h2 *ngIf="orderHeader">{{ orderHeader.amount+400 | currency: "Rs." }}</h2>
        </span>
      <span>
        <h3>No of items</h3>
        <h2>{{ orders.length }}</h2>
      </span>
      <span>
        <h3>Payment Mode</h3>
        <h2 *ngIf="orderHeader">{{ orderHeader.paymentMode }}</h2>
      </span>
    </div>
    <!-- <div  class="price">
      <span  *ngIf="orderStatus">
        <h3>Order Status </h3>        
      <select id="selectorderstaus"  [(ngModel)]="orderstatus_id">
        <option *ngFor="let status of orderStatus" 
        [value]='status.id'>
          {{ status.orderstatus }}</option>
        </select>
      </span>
    </div> -->
    <!-- <div  class="price">
      <span>
        <h3>Payment Mode</h3>        
        <nz-select [(ngModel)]="Paymentmode">
          <nz-option  nzValue="Cash on delivery" nzLabel="Cash on delivery"></nz-option>
          <nz-option  nzValue="Bank transfer" nzLabel="Bank transfer"></nz-option>          
        </nz-select>
        <ng-template #defaultTemplate let-selected>
          {{ selected.nzLabel }}
        </ng-template>
      </span>
    </div> -->
    <div  class="price">
      <span  *ngIf="orderStatus">
        <h3>Order Status </h3>        
        <nz-select [(ngModel)]="orderstatus_id">
          <nz-option  *ngFor="let status of orderStatus" [nzValue]="status.id" [nzLabel]="status.orderstatus" >
          </nz-option>
        </nz-select>
        <ng-template #defaultTemplate let-selected>
          {{ selected.nzLabel }}
        </ng-template>
      </span>
    </div>
    <div class="input-container">
      <label for="notes">Notes</label>
      <input
        nz-input
        type="text"
        name="notes"
        [(ngModel)]="notes"
        placeholder="Enter Notes"
        maxlength="300"
      />
    </div>
    <div class="cta">
      <button
        nz-button
        nzType="primary"
        (click)="submit()"
      [disabled]="orders.length === 0"        
      >
        Save
      </button>
      <button nz-button routerLink="/getOrderSummary">Back to orders</button>
    </div>
  </div>
</div>
  