import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit {
  @Input() title: string;
  @Input() image: string;
  @Input() short_desc: string;
  @Input() category: string;
  @Input() quantity: number;
  @Input() price: string;
  @Input() id: number;
  @Input() onAdd: any;
  @Input() salePrice: string;
  @Input() unitPrice: string;
  @Input() isSale: boolean;
  constructor() {}

  ngOnInit(): void {
    this.isSale = (Number(this.price)>Number(this.salePrice) && Number(this.salePrice) > 0 ? true : false);
    this.unitPrice = (this.isSale ? this.salePrice : this.price);
  }
}
