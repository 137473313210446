<div class="orders-container">
    <h2>Orders</h2>
    {{ error }}
    <nz-table #basicTable [nzData]="orders" *ngIf="orders.length > 0">
      <thead>
        <tr>
          <th></th>
          <th>Status</th>
          <th>Date</th>
          <th>OrderID</th>
          <th>Customer</th>
          <th>Phone</th>   
          <th>Payment Mode</th>                        
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of basicTable.data">
          <td> <span>
            <button routerLink="/order-details/?orderId={{item.order_id}}">View Order</button>
                </span></td> 
          <td>{{ item.orderstatus }}</td> 
          <td>{{ item.order_date | date :'dd/MM/yy'}}</td>
          <td>{{ item.order_id }}</td>
          <td>{{ item.fname }}</td>
          <td>{{ item.phone }}</td>
          <td>{{ item.paymentMode }}</td>       
        </tr>
      </tbody>
    </nz-table>
  </div>
  