import { HttpClient } from '@angular/common/http';
import { Component, Injectable, OnInit,Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ProductService } from '../services/product.service';
import { Product, Products } from '../shared/models/product.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { data } from 'jquery';

@Component({
  selector: 'app-item-search-new',
  templateUrl: './item-search-new.component.html',
  styleUrls: ['./item-search-new.component.scss']
})

export class ItemSearchNewComponent implements OnInit {
    private url = environment.apiUrl;

  dtOptions: DataTables.Settings = {};
  //dtOptions: any = {};

  products: Product[] = [];


  constructor(
    private renderer: Renderer2, private router: Router, private http: HttpClient,
    private productService: ProductService
  ) { }

  ngOnInit(): void {
    this.loadDetails();
  }


  SetParmeters(datatableParameters): string {

    let header: Array<string> = ['CompanyName', 'Email', 'RegistrationNumber', 'Address', 'CityState', 'Country', 'Tel', 'ContactPersonInitials', 'ContactPersonFirstName', 'ContactPersonLastName', 'ContactPersonEmail'];

    
    var offset = datatableParameters.start != undefined && datatableParameters.start != null ? datatableParameters.start : 1;
    var limit = datatableParameters.length != undefined && datatableParameters.length != null ? datatableParameters.length : 100;
    var sortCol = datatableParameters.order.length > 0 ? header[datatableParameters.order[0].column] : "Id";
    var sortDirection = datatableParameters.order.length > 0 && datatableParameters.order[0].dir == "asc" ? "Ascending" : "Descending";
    var searchText = datatableParameters.search.value;


    return '?offset=' + offset + '&limit=' + limit + '&sortCol=' + sortCol + '&sortDirection='
      + sortDirection + "&searchText=" + searchText;
  }

  loadDetails() {

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      responsive: true,
      retrieve: true,
      ajax: (dataTablesParameters: any, callback) => {
        //var token = localStorage.getItem(Appkeys.ACCESS_TOKEN);
        this.http.get<any>(this.url + 'products', {
            params: {
              limit: dataTablesParameters.length != undefined && dataTablesParameters.length != null ? dataTablesParameters.length : 100,
              page: dataTablesParameters.start != undefined && dataTablesParameters.start != null ? dataTablesParameters.start : 1 ,
            },
          }).subscribe(resp => {
            this.products = resp;
            callback({
              recordsTotal: 1000,//resp.numberofEntities,
              recordsFiltered: 1000,//resp.numberofFilterdEntities,
              data: resp,//resp.entities,
            });
          });
      },
      
      columnDefs: [
        { className: 'text-center', targets: [0] },
      ],
      columns: [
        {
            render: function (data: any, type: any, full: any) {
              return '<span><i class="fa fa-fw fa-pencil-square-o text-primary dt-action-btn" aria-hidden="true" view-company-id=' + full.id + '></i></span>';
            }},
        { data: 'category' },
        { data: 'title' },
        { data: 'price' },
        { data: 'quantity' }
      ]
    };

    console.log(this.dtOptions);
  }

}
