
<div class="orders-container">
    <!-- Search Window -->
<div class="content" role="main">
    <div class="card">
      <div class="form-group">
        <label for="search-text">Search Category</label>
        <input type="text" class="form-control" id="search-text" aria-describedby="search-text" 
          [(ngModel)]="searchText" placeholder="Enter Category title to search" 
          autofocus>
      </div>
      <button routerLink="/category">Create Category</button>
    </div>
  </div>
  <!-- Search Window -->
    <h2>Categories</h2>
    {{ error }}
    <nz-table #basicTable [nzData]="categories" *ngIf="categories.length > 0">
      <thead>
        <tr>
          <th></th>
          <th>Category</th>     
          <th>Order By</th>                       
          <th>Hide</th>                  
        </tr>
      </thead>
      <tbody>
      <tr class="list-group-item" *ngFor="let c of categories | appFilter: searchText">
      <!-- <tr class="list-group-item" *ngFor="let c of basicTable.data  | appFilter: searchText"> -->
          <td> <span>
            <button routerLink="/category/{{c.id}}">Modify Category</button>
           </span></td> 
          <td>{{ c.title }}</td>   
          <td>{{ c.orderby }}</td>                    
          <td >{{ (c.hide?'Yes':'No') }}</td>                 
        </tr>
      </tbody>
    </nz-table>
  </div>
  
