import { Component, OnInit } from '@angular/core';
import { ProductService } from '../services/product.service';

@Component({
  selector: 'app-category-search',
  templateUrl: './category-search.component.html',
  styleUrls: ['./category-search.component.scss']
})
export class CategorySearchComponent implements OnInit {

  searchtitle = 'angular-text-search-highlight';
  searchText = '';
  error = '';
  loading = false;
  categories: [] = [];

  constructor(private productService: ProductService) { }

  ngOnInit(): void {
    this.loading = true;
    setTimeout(() => {
      this.productService.getAllCategories().subscribe(
        (res: any) => {
          //console.log(res);
          this.categories = res;
          this.loading = false;
        },
        (err) => {
          console.log(err);
          this.loading = false;
        }
      );
    }, 500);
  }

}
