
  <div class="orders-container">
    <!-- Search Window -->
<div class="content" role="main">
    <div class="card">
      <div class="form-group">
        <label for="search-text">Search Item</label>
        <input type="text" class="form-control" id="search-text" aria-describedby="search-text" 
          [(ngModel)]="searchText" placeholder="Enter Item title to search" 
          autofocus>
      </div>
      <button routerLink="/item">Create Item</button>
    </div>
  </div>
  <!-- Search Window -->
    <h2>Items</h2>
    {{ error }}
    <nz-table #basicTable [nzData]="products" *ngIf="products.length > 0">
      <thead>
        <tr>
          <th></th>
          <th>Category</th>   
          <th>Item Id</th>   
          <th>Item</th>   
          <th>Price</th>                     
          <th>Quantity</th>  
          <th>Sale Price</th>                    
        </tr>
      </thead>
      <tbody>
      <tr class="list-group-item" *ngFor="let c of products | appFilter: searchText">
      <!-- <tr class="list-group-item" *ngFor="let c of basicTable.data  | appFilter: searchText"> -->
          <td> <span>
            <button routerLink="/item/{{c.id}}">Modify Item</button>
           </span></td> 
          <td>{{ c.category }}</td>
          <td>{{ c.id }}</td> 
          <td>{{ c.title }}</td> 
          <td>{{ c.price }}</td>               
          <td>{{ c.quantity }}</td>   
          <td>{{ c.salePrice }}</td>                     
        </tr>
      </tbody>
    </nz-table>
  </div>
  
