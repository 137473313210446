<div class="home-container" >
  <div class="categories">
    <div class="categories__header">
      <h2>Categories</h2>
    </div>
    <!-- <div class="categories__list">
      
      <swiper 
        [navigation]="true" 
        [zoom]="false"
        [slidesPerView]="screenWidth > 1200 ? 5 : 1"
        [spaceBetween]="10"
        [pagination]="pagination"
        class="mySwiper"
      >
        <ng-template
          swiperSlide
          [zoom]="true"
          *ngFor="let c of categories; index as i"
        >
          <img src="{{ image }}" alt="`Category image`" /> 
          <div class="category-card" [value]='c.id'  (click)="click_category(c.id,c.title)">{{ c.title }}</div>

        </ng-template>
      </swiper>
    </div> -->
    <!-- <div class="categories__list">
          
      <swiper
        [navigation]="true"
        [zoom]="false"
        [slidesPerView]="screenWidth > 1200 ? 5 : 1"
        [spaceBetween]="10"
        [pagination]="pagination"
        [loop]="false"
      >
        <ng-template swiperSlide *ngFor="let c of categories">
          <div class="category-card" [value]='c.id'  (click)="click_category(c.id,c.title)">{{ c.title }}</div>
          <Div>
            <img src="{{ c.image }}" alt="`Category image`" /> 
          </Div>
        </ng-template>

      </swiper>
    </div> -->
    <div *ngIf="Catloading" class="products__loading">
      <ng-template #indicatorTemplate>
        <i
          nz-icon
          nzType="loading"
          [ngStyle]="{ 'font-size': '2rem', margin: '0 auto' }"
        ></i>
      </ng-template>
      <nz-spin nzSimple [nzIndicator]="indicatorTemplate"> </nz-spin>
    </div>
    <div *ngIf="!Catloading" >
      <swiper    
    [grabCursor]="true"
    [centeredSlides]="true"
    [slidesPerView]="screenWidth > 1200 ? 3 : 'auto'"
    [spaceBetween]="30"
    [centeredSlides]="true"
    [pagination]="true"
    [autoplay]="{
      delay: 2500,
      disableOnInteraction: false
    }"
    [pagination]="{
      clickable: true
    }"
    [navigation]="true"
    [grid]="{
      rows: 3,
      fill: 'column'
    }"
    class="mySwiper"
  >
  <ng-template swiperSlide *ngFor="let c of categories">
    {{ c.title }}
    <img  [value]='c.id' alt="`Category image`"  (click)="click_category(c.id,c.title)" src="{{c.image}}" />

    <!-- <div class="category-card" [value]='c.id'  (click)="click_category(c.id,c.title)">{{ c.title }}</div>
    <Div>
      <img src="{{ c.image }}" alt="`Category image`" /> 
    </Div> -->
  </ng-template>
    <!-- <ng-template swiperSlide
      >End<img src="https://swiperjs.com/demos/images/nature-9.jpg"
    /></ng-template> -->
  </swiper>
    </div>
  </div>
  <div class="products">
    <div class="products__header">
      <h2>Products</h2>
    </div>
    <div *ngIf="loading" class="products__loading">
      <ng-template #indicatorTemplate>
        <i
          nz-icon
          nzType="loading"
          [ngStyle]="{ 'font-size': '2rem', margin: '0 auto' }"
        ></i>
      </ng-template>
      <nz-spin nzSimple [nzIndicator]="indicatorTemplate"> </nz-spin>
    </div>
    <div class="products__list" *ngIf="!loading">
      <app-product-card
        *ngFor="let n of products"
        [id]="n.id"
        [title]="n.title"
        [image]="n.image1"
        [price]="n.price"
        [salePrice]="n.salePrice"
        [short_desc]="n.short_desc"
        [category]="n.category"
        [quantity]="n.quantity"
        [onAdd]="cartService.addProduct.bind(cartService)"
      >
      </app-product-card>
    </div>
  </div>
  <div class="load-products">
    <button
      nz-button
      (click)="showMoreProducts()"
      [nzLoading]="additionalLoading"
    >
      Show more
    </button>
  </div>
</div>
