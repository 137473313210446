import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Products, Product } from '../shared/models/product.model';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';


@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private url = environment.apiUrl;

    
    constructor(private http: HttpClient, private _api: ApiService,private _notification: NzNotificationService) {}

  getAllProducts(limitOfResults = 9, page): Observable<Products> {

    return this.http.get<Products>(this.url + 'products', {
      params: {
        limit: limitOfResults.toString(),
        page: page,
      },
    });
  }

  getAllProductsSummary(limitOfResults = 9, page): Observable<Products> {

    return this.http.get<Products>(this.url + 'products/getallSummary', {
      params: {
        limit: limitOfResults.toString(),
        page: page,
      },
    });
  }

  getAllActiveProducts(limitOfResults = 9, page): Observable<Products> {

    return this.http.get<Products>(this.url + 'products/getallactive', {
      params: {
        limit: limitOfResults.toString(),
        page: page,
      },
    });
  }

  getProductsByCategory(limitOfResults = 9, page,categoryid): Observable<Products> {

    return this.http.get<Products>(this.url + 'products/getbycategory', {
      params: {
        limit: limitOfResults.toString(),
        page: page,
        categoryid: categoryid,
      },
    });
  }

  getSingleProduct(id: Number): Observable<any> {
    console.log(id);
    return this._api.getTypeRequest('products/' + id);
  }

  getAllCategories(): Observable<any> {
    return this._api.getTypeRequest('products/Categories');
  }

  getCartCategories(): Observable<any> {
    return this._api.getTypeRequest('products/CartCategories');
  }

  getSingleCategory(id: Number): Observable<any> {
    console.log(id);
    return this._api.getTypeRequest('products/getcategorybyid/' + id);
  }

  saveProduct(params) {
    
    const { id, title,image1,image2,image3,image4,description,price,quantity,cat_id,short_desc,salePrice } = params;    
       
     return this._api.postTypeRequest('products/create',{
       id:id,
       title:title,
       image1:image1,
       image2:image2,
       image3:image3,
       image4:image4,
       description:description,
       price:price,
       quantity:quantity,
       cat_id:cat_id,
       short_desc:short_desc,
       salePrice:salePrice,
     });   

  }

  saveCategory(params) {
    
    const { id, title,image,orderby,hide } = params;    
       
     return this._api.postTypeRequest('products/createCategory',{
       id:id,
       title:title,
       image:image,
       orderby:orderby,
       hide:hide,
     });   

  }
}
