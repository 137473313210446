import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CartComponent } from './cart/cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './auth/components/login/login.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { ProductComponent } from './product/product.component';
import { ProfileComponent } from './profile/profile.component';
import { RegisterComponent } from './auth/components/register/register.component';
import { AuthGuardService } from './guards/auth-guard.service';
import { ItemComponent } from './item/item.component';
import { OrdersComponent } from './orders/orders.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { ItemSearchComponent } from './item-search/item-search.component';
import { AuthService } from './services/auth.service';
import { AuthGuardServiceAdmin } from './guards/auth-guard-admin.service';
import { AuthGuardServicePowerUser } from './guards/auth-guard-power-user.service';
import { ItemSearchNewComponent } from './item-search-new/item-search-new.component';
import { CategoryComponent } from './category/category.component';
import { CategorySearchComponent } from './category-search/category-search.component';
import { SampleComponent } from './sample/sample.component';
import { AuthGuardServiceOrderHistory } from './guards/auth-guard-OrderHistory.service';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuardServiceOrderHistory],
  },
  { path: 'product/:id', component: ProductComponent },
  { path: 'cart', component: CartComponent },
  {
    path: 'checkout',
    component: CheckoutComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'order-history',
    component: OrderHistoryComponent,
    canActivate: [AuthGuardServiceOrderHistory],
  },
  {
    path: 'item',
    component: ItemComponent,
    canActivate: [AuthGuardServicePowerUser],
  },
  {
    path: 'item/:itemId',
    canActivate: [AuthGuardServicePowerUser],
    component: ItemComponent,
  },
  {
    path: 'getOrderSummary',
    component: OrdersComponent,
    canActivate: [AuthGuardServiceAdmin],
  },
  {
    path: 'order-details/:orderId',
    component: OrderDetailsComponent,
    canActivate: [AuthGuardServiceAdmin],
  },
  {
    path: 'item-search',
     component: ItemSearchComponent,
    //component: ItemSearchNewComponent,
    canActivate: [AuthGuardServicePowerUser],
  },
  {
    path: 'category',
    component: CategoryComponent,
    canActivate: [AuthGuardServicePowerUser],
  },
  {
    path: 'category/:categoryId',
    canActivate: [AuthGuardServicePowerUser],
    component: CategoryComponent,
  },
  {
    path: 'category-search',
     component: CategorySearchComponent,
    //component: ItemSearchNewComponent,
    canActivate: [AuthGuardServicePowerUser],
  },
  {
    path: 'SampleComponent',
     component: SampleComponent,
    //component: ItemSearchNewComponent,
    canActivate: [AuthGuardServicePowerUser],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  user: any;
  constructor(
    private _auth: AuthService,
  ) {
    this.user = this._auth.getUser();
  }


}
