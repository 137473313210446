import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './auth/components/login/login.component';
import { RegisterComponent } from './auth/components/register/register.component';
import { CartComponent } from './cart/cart.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { ProductComponent } from './product/product.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ProfileComponent } from './profile/profile.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { AuthModule } from './auth/auth.module';
import { FormsModule } from '@angular/forms';
import { authInterceptorProviders } from './services/interceptor.service';
import { AuthGuardService } from './guards/auth-guard.service';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData ,HashLocationStrategy,LocationStrategy} from '@angular/common';
import en from '@angular/common/locales/en';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { SwiperModule } from 'swiper/angular';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzTableModule } from 'ng-zorro-antd/table';
import { ItemComponent } from './item/item.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ReactiveFormsModule } from '@angular/forms';
import { OrdersComponent } from './orders/orders.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { FilterPipe } from './pipes/filter.pipe'; // -> imported filter pipe
import { HighlightDirective } from './directives/highlight.pipe';
import { ItemSearchComponent } from './item-search/item-search.component'; // ->  imported directive
import { AuthGuardServiceAdmin } from './guards/auth-guard-admin.service';
import { AuthGuardServicePowerUser } from './guards/auth-guard-power-user.service';
import { AuthGuardServiceOrderHistory } from './guards/auth-guard-OrderHistory.service';
import { ItemSearchNewComponent } from './item-search-new/item-search-new.component';
import { DataTablesModule } from 'angular-datatables';
import { CategoryComponent } from './category/category.component';
import { CategorySearchComponent } from './category-search/category-search.component';
import { SampleComponent } from './sample/sample.component';
import { NzSelectModule } from 'ng-zorro-antd/select';

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    CartComponent,
    OrderHistoryComponent,
    ProductComponent,
    CheckoutComponent,
    ProfileComponent,
    HeaderComponent,
    FooterComponent,
    ProductCardComponent,
    ItemComponent,
    FileUploadComponent,
    OrdersComponent,
    OrderDetailsComponent,
    FilterPipe, // -> added filter pipe to use it inside the component
    HighlightDirective, ItemSearchComponent, ItemSearchNewComponent, CategoryComponent, CategorySearchComponent, SampleComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    IvyCarouselModule,
    HttpClientModule,
    AuthModule,
    FormsModule,
    BrowserAnimationsModule,
    NzButtonModule,
    NzDropDownModule,
    NzIconModule,
    NzInputModule,
    NzAlertModule,
    NzInputNumberModule,
    SwiperModule,
    NzSpinModule,
    NzNotificationModule,
    NzProgressModule,
    NzTableModule,
    ReactiveFormsModule,
    DataTablesModule,NzSelectModule,
  ],
  providers: [
    authInterceptorProviders,
    AuthGuardService,
    AuthGuardServiceAdmin,
    AuthGuardServicePowerUser,
    AuthGuardServiceOrderHistory,
    { provide: NZ_I18N, useValue: en_US },
    { provide: LocationStrategy , useClass:HashLocationStrategy},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
