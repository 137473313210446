 
<div id="sides">
    <div id="left">      
<div class="register-container">
    <div class="form-container">
      <h2>Item</h2>
      <nz-alert
        *ngIf="errorMessage"
        nzShowIcon
        nzType="error"
        [nzMessage]="errorMessage"
        [ngStyle]="{ 'margin-bottom': '16px' }"
      ></nz-alert>
      <form #registerForm="ngForm" (ngSubmit)="onSubmit()">
        <div class="input-container">
          <label for="title">Item Name</label>
          <input
            nz-input
            type="text"
            name="title"
            [(ngModel)]="title"
            placeholder="Enter Item Name"
          />
        </div>
        <div class="input-container">
            <label for="description">Item Description</label>
            <input
              nz-input
              type="text"
              name="description"
              [(ngModel)]="description"
              placeholder="Enter Item Description"
            />
          </div>
          <div class="input-container">
            <label for="short_desc">Item Short Description</label>
            <input
              nz-input
              type="text"
              name="short_desc"
              [(ngModel)]="short_desc"
              placeholder="Enter Short Description"
            />
          </div>
          <div class="input-container">
            <span  *ngIf="categories">
              Category        
            <select id="selected_cat_id"  [(ngModel)]="cat_id"  [ngModelOptions]="{standalone: true}">
              <option *ngFor="let cat of categories" 
              [value]='cat.id'>
                {{ cat.title }}</option>
              </select>
            </span>
          </div>
          <div class="‼️">
            <div class="input-container">
              <label for="price">Price</label>
              <input  
                type="number"
                min="0" 
                nz-input
                name="price"
                [(value)]="price"
                [(ngModel)]="price"
              />
            </div>
            <div class="input-container">
              <label for="quantity">Quantity</label>
              <input
                nz-input
                type="number"
                min="0" 
                name="quantity"
                [(value)]="quantity"
                [(ngModel)]="quantity"
              />
            </div>
          </div>  
          <div class="‼️">
            <div class="input-container">
              <label for="salePrice">Sale Price</label>
              <input  
                type="number"
                min="0" 
                nz-input
                name="salePrice"
                [(value)]="salePrice"
                [(ngModel)]="salePrice"
              />
            </div>   
          </div>        
        <div class="cta-container">
          <!-- <input type="submit" value="Sign up" /> -->
          <button
            nz-button
            type="submit"
            [disabled]="!canSubmit()"
            [nzLoading]="loading"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<div id="right">
    <div class="register-container">
        <div class="form-container">
          <h2>Images</h2>
          <form [formGroup]="uploadForm" (ngSubmit)="submit()">
            
            <!-- Select File -->
            <input type="file" accept="image/*" (change)="showPreview($event,1)" />
            <!-- Image Preview -->
            <div class="product-card__image" *ngIf="imageURL1 && imageURL1 !== ''">
              <img [src]="imageURL1" [alt]="uploadForm.value.name" style='height: 100%; width: 100%; object-fit: contain'>
            </div>
            
            <!-- Select File -->
            <input type="file" accept="image/*" (change)="showPreview($event,2)" />
            <!-- Image Preview -->
            <div class="product-card__image" *ngIf="imageURL2 && imageURL2 !== ''">
              <img [src]="imageURL2" [alt]="uploadForm.value.name" style='height: 100%; width: 100%; object-fit: contain'>
            </div>

            <!-- Select File -->
            <input type="file" accept="image/*" (change)="showPreview($event,3)" />
            <!-- Image Preview -->
            <div class="product-card__image" *ngIf="imageURL3 && imageURL3 !== ''">
              <img [src]="imageURL3" [alt]="uploadForm.value.name" style='height: 100%; width: 100%; object-fit: contain'>
            </div>

            <!-- Select File -->
            <input type="file" accept="image/*" (change)="showPreview($event,4)" />
            <!-- Image Preview -->
            <div class="product-card__image" *ngIf="imageURL4 && imageURL4 !== ''">
              <img [src]="imageURL4" [alt]="uploadForm.value.name" style='height: 100%; width: 100%; object-fit: contain'>
            </div>
          </form>
          
        </div>
      </div>
</div>
</div>

  