import {
  Component,
  OnInit,
  ViewEncapsulation,
  HostListener,ViewChild,
} from '@angular/core';
import { CartService } from '../services/cart.service';
import { ProductService } from '../services/product.service';
import { Products, Product } from '../shared/models/product.model';

// import Swiper core and required components
import SwiperCore, {
  Navigation,
  Pagination
} from 'swiper/core';

import { SwiperComponent } from "swiper/angular";


import "swiper";
import "swiper/components/effect-coverflow";
import "swiper/components/pagination";


// install Swiper modules
SwiperCore.use([Pagination,Navigation]);


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
  products: Product[] = [];
  categories: any[] = [];
  loading = false;
  Catloading = false;
  productPageCounter = 1;
  additionalLoading = false;
  selectedCategoryId=0;

  constructor(
    private productService: ProductService,
    private cartService: CartService
  ) {}

  public screenWidth: any;
  public screenHeight: any;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.loading = true;
    this.Catloading = true;
    setTimeout(() => {
      this.productService.getAllActiveProducts(9, this.productPageCounter).subscribe(
        (res: any) => {
          //console.log(res);
          this.products = res;
          this.loading = false;
        },
        (err) => {
          console.log(err);
          this.loading = false;
        }
      );
    }, 500);

    
    setTimeout(() => {
      this.productService.getCartCategories().subscribe(
        (res: any) => {
          this.categories = res;
          this.Catloading = false;
        },
        (err) => {
          console.log(err);
          this.Catloading = false;
        }
      );
    }, 500);

  }

  showMoreProducts(): void {
    this.additionalLoading = true;
    this.productPageCounter = this.productPageCounter + 1;
    console.log(this.selectedCategoryId);
    setTimeout(() => {
      (this.selectedCategoryId>0? this.productService.getProductsByCategory(9, this.productPageCounter,this.selectedCategoryId) : this.productService.getAllActiveProducts(9, this.productPageCounter)).subscribe(
        (res: any) => {
          console.log(res);
          this.products = [...this.products, ...res];
          this.additionalLoading = false;
        },
        (err) => {
          console.log(err);
          this.additionalLoading = false;
        }
      );
    }, 500);
  }

  click_category(id:any,title:any): void {
    this.loading = true;
    console.log(id);
    this.selectedCategoryId=id;
    this.productPageCounter = 1;
    setTimeout(() => {
      (title==="ALL" ? this.productService.getAllProducts(9, this.productPageCounter) : this.productService.getProductsByCategory(9, this.productPageCounter,id)).subscribe(
        (res: any) => {
          //console.log(res);
          this.products = res;
          this.loading = false;
        },
        (err) => {
          console.log(err);
          this.loading = false;
        }
      );
    }, 500);
  }

  pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };
}
