import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductService } from '../services/product.service';
import { map } from 'rxjs/operators';
import { CartService } from '../services/cart.service';

// import Swiper core and required components
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller,
} from 'swiper/core';


// install Swiper components
SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller,
]);

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit {
  id: number;
  product: any;
  quantity: number;
  showcaseImages: any[] = [];
  loading = false;
  isSale: boolean;

  constructor(
    private _route: ActivatedRoute,
    private _product: ProductService,
    private _cart: CartService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this._route.paramMap
      .pipe(
        map((param: any) => {
          return param.params.id;
        })
      )
      .subscribe((productId) => {
        // returns string so convert it to number
        this.id = parseInt(productId);
        this._product.getSingleProduct(productId).subscribe((product) => {
          console.log(product);
          this.product = product;
          this.isSale = (Number(this.product.price)>Number(this.product.salePrice) && Number(this.product.salePrice) > 0 ? true : false);
          this.product.unitPrice = (this.isSale ? this.product.salePrice : this.product.price);
          if (product.quantity === 0) this.quantity = 0;
          else this.quantity = 1;

          // if (product.images) {
          //   //this.showcaseImages = product.images.split(';');
          //   var img= product.images.split(';');
          //   img.forEach(element => {
          //     var binaryData = [];
          //     binaryData.push(element);
          //     //this.showcaseImages.push(window.URL.createObjectURL(new Blob(binaryData, {type: "application/zip"})));
          //       //this.showcaseImages.push((binaryData));                           
          //   });
          //   //this.showcaseImages = product.images.split(';');
          //   this.showcaseImages.push(product.image);
          //   console.log(this.showcaseImages);
          // }
          if(product.image1!='')
            this.showcaseImages.push(product.image1);
          if(product.image2!='')          
            this.showcaseImages.push(product.image2);
          if(product.image3!='')          
            this.showcaseImages.push(product.image3);
          if(product.image4!='')
            this.showcaseImages.push(product.image4);
          this.loading = false;
        });
      });
  }

  addToCart(): void {
    this._cart.addProduct({
      id: this.id,
      price: this.product.unitPrice,
      quantity: this.quantity,
      image: this.product.image,
      title: this.product.title,
      maxQuantity: this.product.quantity,
    });
  }

  pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };

}
